<template>
  <div :class="['div-input', className]">
    <div class="display-only" v-if="isDisplay"></div>
    <div :class="['input-custom', { error: (v && v.$error) || isError }]">
      <label v-if="textFloat" class="title mb-1">
        {{ textFloat }}
        <span v-if="isRequired" class="text-danger">*</span>
        <span v-if="textWarning" class="text-warning-message ml-3">{{
          textWarning
        }}</span>
        <span v-if="textDesc" class="text-desc-message">{{ textDesc }}</span>
        <slot name="text"></slot>
      </label>
      <div :class="[{ 'has-icon': faIcon || hasIcon }]">
        <div class="warp-input">
          <masked-input
            :class="['custom-input', { 'text-gray': isPlaceholder }]"
            :type="type == 'password' ? passwordType : type"
            :placeholder="placeholder"
            :required="required"
            v-model="val"
            :size="size"
            :mask="mask"
            :disabled="disabled"
            @input="onDataChange"
            :masked="masked"
          />
        </div>
        <div v-if="isShowPassword" :class="['button-eye']">
          <font-awesome-icon
            v-if="passwordType == 'password'"
            @click="handleShowHidePassword"
            :icon="['fas', 'eye-slash']"
            class="pointer"
          />
          <font-awesome-icon
            v-else
            @click="handleShowHidePassword"
            :icon="['fas', 'eye']"
            class="pointer"
          />
        </div>
        <div v-if="faIcon" :class="['fa-icon-custom', 'pointer']">
          <font-awesome-icon
            @click="$emit('onClickIcon', val)"
            :icon="faIcon"
            class="pointer"
          />
        </div>
        <slot name="icon"> <div @click="$emit('onClickIcon', val)"></div></slot>
      </div>
    </div>
    <img :src="img" alt="logo-lang" v-if="img" class="logo-lang" />
    <span v-if="detail" class="text-desc text-wrap">{{ detail }}</span>
    <div v-if="v && v.$error">
      <span class="text-error" v-if="v.required == false">กรุณากรอกข้อมูล</span>
      <span class="text-error" v-else-if="v.minLength == false"
        >กรุณากรอกตัวอักษรอย่างต่ำ {{ v.$params.minLength.min }} ตัวอักษร
      </span>
      <span class="text-error" v-else-if="v.email == false"
        >กรุณากรอกอีเมลให้ถูกต้อง (เช่น email@example.com).</span
      >
      <span class="text-error" v-else-if="v.integer == false"
        >กรุณากรอกตัวเลขเท่านั้น
      </span>
      <span class="text-error" v-else-if="v.maxLength == false"
        >กรุณากรอกตัวอักษรมากสุด {{ v.$params.maxLength.max }} ตัวอักษร
      </span>
      <span class="text-error" v-else-if="v.decimal == false"
        >กรุณากรอกตัวเลขเท่านั้น
      </span>
      <span class="text-error" v-else-if="v.minValue == false"
        >กรุณากรอกตัวเลขมากกว่า 0
      </span>
      <span class="text-error" v-else-if="v.minValueZero == false"
        >กรุณากรอกตัวเลขไม่น้อยกว่า 0
      </span>
      <span class="text-error d" v-else-if="v.sameAsPassword == false"
        >รหัสผ่านต้องสัมพันธ์กัน
      </span>
      <span class="text-error" v-else-if="v.validateMaxLength == false"
        >กรุณากรอกตัวอักษรมากสุด {{ maxLength }} ตัวอักษร
      </span>
      <span class="text-error" v-else-if="v.alpha == false"
        >กรุณากรอกตัวอักษรเท่านั้น
      </span>
      <span class="text-error" v-else-if="v.alphaNum == false"
        >กรุณากรอกตัวอักษรหรือตัวเลขเท่านั้น
      </span>
      <span class="text-error" v-else-if="v.password == false"
        >รหัสผ่านจะต้องประกอบด้วยตัวอักษร a-z และ 1-9 ควรมีความยาวไม่ต่ำกว่า 8
        ตัวอักษร</span
      >
      <span class="text-error" v-else-if="v.handleMaxTransfer == false"
        >จำนวนที่ต้องการโอนต้องน้อยกว่าหรือเท่ากับจำนวนปัจจุบัน</span
      >
      <span class="text-error" v-else-if="v.maxValueToday == false"
        >วันที่ต้องน้อยกว่าหรือเท่ากับวันปัจจุบัน
      </span>

      <span class="text-error" v-else>{{ customMessage }} </span>
    </div>
  </div>
</template>

<script>
import MaskedInput from 'vue-masked-input';

export default {
  props: {
    textFloat: {
      required: false,
      type: String
    },
    placeholder: {
      required: false,
      type: String
    },
    type: {
      required: false,
      type: String,
      default: 'text'
    },
    required: {
      required: false,
      type: Boolean
    },
    detail: {
      required: false,
      type: String
    },
    size: {
      required: false,
      type: String
    },
    value: {
      required: false,
      type: [String]
    },
    isDisplay: {
      required: false,
      type: Boolean
    },
    isRequired: {
      required: false,
      type: Boolean
    },
    isValidate: {
      required: false,
      type: Boolean
    },
    v: {
      required: false,
      type: Object
    },
    className: {
      required: false,
      type: String
    },
    img: {
      required: false,
      type: String
    },
    disabled: {
      required: false,
      type: Boolean
    },
    icon: {
      required: false,
      type: String
    },
    maxLength: {
      required: false,
      type: Number
    },
    isShowPassword: {
      required: false,
      type: Boolean
    },
    textWarning: {
      required: false,
      type: String
    },
    textDesc: {
      required: false,
      type: String
    },
    faIcon: {
      required: false,
      type: String
    },
    hasIcon: {
      required: false,
      type: Boolean,
      default: false
    },
    textField: {
      required: false,
      type: String
    },
    mask: {
      required: true,
      type: String
    },
    masked: {
      required: false,
      type: Boolean,
      default: false
    },
    customMessage: {
      required: false,
      type: String
    },
    isError: {
      required: false,
      type: Boolean
    }
  },
  components: {
    MaskedInput
  },
  data() {
    return {
      passwordType: 'password',
      isDisplayAutoComplete: false,
      val: this.value,
      initialVal: this.value || "",
    };
  },
  watch: {
    value: {
      handler() {
        this.val = this.value;
      },
      immediate: true
    }
  },
  computed: {
    isPlaceholder() {
      return this.val === this.initialVal;
    },
  },
  methods: {
    onDataChange(event) {
      this.$emit('onDataChange', event);
    },
    handleShowHidePassword() {
      if (this.passwordType == 'password') {
        this.passwordType = 'text';
      } else {
        this.passwordType = 'password';
      }
    }
  }
};
</script>

<style scoped>
.div-input {
  position: relative;
  white-space: nowrap;
}
.div-input .title {
  color: #333 !important;
  font-size: 14px !important;
  font-weight: bold !important;
  width: 100%;
  position: relative;
}
.input-custom {
  padding: 0px;
}
.input-border-0 .input-custom input {
  border: 0 !important;
}
.input-custom .has-icon {
  width: 100%;
  display: flex;
}

.warp-input {
  display: block;
  border: none;
  width: 100%;
  /* width: calc(100% - 32px - 10px); */
  position: relative;
}
.rounded .input-custom .has-icon .warp-input {
  width: calc(100% - 32px - 10px);
}
.rounded .input-custom .has-icon input {
  border-radius: 20px !important;
  border: none;
  padding: 2px 10px;
  text-align: center;
  border: 1px solid transparent !important;
  width: 100%;
}
.rounded .input-custom .has-icon input:focus {
  border: 1px solid var(--primary-color) !important;
}
.input-custom input {
  color: #404040;
  border: 1px solid #bcbcbc !important;
  border-radius: 10px;
  padding: 10px;
  font-size: 16px !important;
  text-align: center;
}
.input-custom input[size='lg'] {
  height: 55px;
}
.input-custom input:focus {
  border: 1px solid var(--primary-color) !important;
}
.input-custom.error input {
  border-color: red !important;
}
::-webkit-input-placeholder {
  /* Edge */
  color: #c9c9c9;
}
:-ms-input-placeholder {
  /* Internet Explorer */
  color: #c9c9c9;
}
::placeholder {
  color:#c9c9c9;
}
.warp-input {
  display: block;
  border: none;
  width: 100%;
  position: relative;
}
.custom-input {
  display: block;
  border: none;
  width: 100%;
}
.text-desc {
  color: #9b9b9b;
  font-size: 12px;
}
.display-only {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.d-grid {
  display: grid;
}
.button-eye {
  position: absolute;
  right: 20px;
  bottom: 0.75rem;
  z-index: 6;
}
.text-warning-message {
  color: #bebebe;
  font-size: 12px;
}
.text-desc-message {
  color: #999;
  text-align: right;
  position: absolute;
  right: 0;
  font-weight: normal;
}
.fa-icon-custom {
  width: 32px;
  height: 32px;
  margin-left: 10px;
  border-radius: 50%;
  border: 1px none #fff;
  background-color: var(--primary-color) !important;
  padding: 5px 7px;
}
.fa-icon-custom svg {
  color: white !important;
  font-size: 18px;
}

.auto-complete-customs {
  background: white;
  position: absolute;
  width: 100%;
  top: 32px;
  z-index: 1;
  border: 1px solid #bcbcbc !important;
  max-height: 150px;
  overflow-y: auto;
}
.auto-complete-customs > div {
  padding: 5px;
  font-size: 14px;
  cursor: pointer;
}
.auto-complete-customs > div:hover {
  background-color: var(--primary-color) !important;
  color: white !important;
}
.text-gray {
  color: #c9c9c9;
}
</style>
